<template>
  <Loading class="min-h-screen" :loading="loading" />
  <div class="py-3.5 px-6">
    <div class="flex items-center flex-wrap">
      <p class="text-gray-400 text-base flex-grow items-center m-0">Add board to congratulate someone you love</p>
      <div>
        <a-button class="ant-btn ant-btn-primary" size="large" @click.prevent="modalVisible = true">
          <PlusOutlined />
          New board
        </a-button>
      </div>
    </div>
  </div>

  <div class="bg-gray-200 px-6 py-2 flex items-center">
    <div class="flex-grow text-gray-400">Boards list</div>
    <div>
      <a-input-search v-model:value="searchQuery" placeholder="search board" />
    </div>
  </div>

  <div class="container-fluid m-auto min-h-screen px-6 py-4">
    <BoardAlertMessage />

    <a-empty v-if="!loading && !boards.length" />
    <template v-for="(boards, month) in boardsGroupPerMonth" :key="month">
      <a-divider>{{ month }}</a-divider>

      <BoardItem v-for="board in boards" :key="board.id" :board="board" />
    </template>

    <div class="text-right">
      <a-pagination
        class="mt-4"
        v-model:current="paginationConfig.currentPage"
        v-model:page-size="paginationConfig.pageSize"
        :total="paginationConfig.total"
        show-size-changer
        show-quick-jumper
      />
    </div>
  </div>
  <BoardFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :id="selectedBoardId"
    @update:closeModal="modalVisible = false"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { LOAD_BOARDS, LOAD_BOARDS_RECEIVED, LOAD_BOARDS_WAITING } from '@/store/actions'
import { IBoard, IUser } from '@/utils/types'
import { DashboardOutlined, FormOutlined, EnterOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'

import BoardFormModal from '@/components/board/BoardFormModal.vue'
import BoardItem from '@/components/board/BoardItem.vue'
import BoardAlertMessage from '@/components/board/BoardAlertMessage.vue'
import Loading from '@/components/common/Loading.vue'
import { Watch } from 'vue-property-decorator'
import { BOARD_TABS } from '@/utils/constants'
import moment from 'moment-timezone'

@Options({
  components: {
    BoardItem,
    BoardFormModal,
    BoardAlertMessage,
    DashboardOutlined,
    PlusOutlined,
    FormOutlined,
    EnterOutlined,
    SearchOutlined,
    Loading,
  },
  directives: { maska },
})
export default class BoardList extends Vue {
  searchQuery = ''
  selectedBoardId = ''
  activeTabKey = ''
  modalVisible = false
  tabKeys = [BOARD_TABS.gives, BOARD_TABS.received, BOARD_TABS.waiting_for_you]
  paginationConfig = {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  }

  get loading() {
    return this.$store.state.board.loading
  }

  get boards(): IBoard[] {
    const boards = this.$store.state.board.items
    const boardsFiltered = boards.filter((item: IBoard) => {
      const match1 = String(item.title).toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1

      return match1
    })

    this.paginationConfig.total = boardsFiltered.length

    return boardsFiltered.splice(
      (this.paginationConfig.currentPage - 1) * this.paginationConfig.pageSize,
      this.paginationConfig.pageSize
    )
  }

  get boardsGroupPerMonth() {
    const boards: Record<string, IBoard[]> = {}
    for (const board of this.boards) {
      const month = moment.unix(board.createdAt || 0).format('YYYY-MM')
      const currentList = boards[month] || []
      currentList.push(board)
      boards[month] = currentList
    }

    return boards
  }

  get hasWaitingForYouBoard() {
    return true
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Watch('activeTabKey')
  @Watch('userInfo')
  activeTabKeyChange() {
    if (!this.userInfo?.id) {
      return
    }

    if (!this.tabKeys.includes(this.activeTabKey)) {
      return
    }

    this.$router.push({
      name: this.activeTabKey,
    })

    if (this.activeTabKey === BOARD_TABS.received) {
      this.getBoardsReceived()
    } else if (this.activeTabKey === BOARD_TABS.waiting_for_you) {
      this.getBoardsWaiting()
    } else {
      this.getBoards()
    }
  }

  @Watch('$route')
  routerChanged() {
    this.setActiveTab()
  }

  setActiveTab() {
    this.activeTabKey = this.$route.name === 'home' ? 'gives' : this.$route.name
  }

  onOpenEditForm(id: string) {
    this.selectedBoardId = id
  }

  closeModalBoardEdit() {
    this.selectedBoardId = ''
  }

  async getBoards() {
    await this.$store.dispatch(LOAD_BOARDS, this.userInfo)
  }

  async getBoardsReceived() {
    await this.$store.dispatch(LOAD_BOARDS_RECEIVED, this.userInfo)
  }

  async getBoardsWaiting() {
    await this.$store.dispatch(LOAD_BOARDS_WAITING, this.userInfo)
  }

  created() {
    this.setActiveTab()
  }
}
</script>
