
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IUser } from '@/utils/types'
import { SmileOutlined, ArrowRightOutlined } from '@ant-design/icons-vue'
import { BOARD_TABS } from '@/utils/constants'

@Options({
  components: { SmileOutlined, ArrowRightOutlined },
  directives: { maska },
  emits: [],
})
export default class BoardViewItem extends Vue {
  message = ''

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get canEdit() {
    return this.boardItem.userId === this.userInfo.id
  }

  get shouldDisplayByRouter() {
    return this.$route.name !== BOARD_TABS.waiting_for_you
  }

  gotoWaitingForYou() {
    this.$router.push({
      name: BOARD_TABS.waiting_for_you,
    })
  }

  async created() {
    if (!this.shouldDisplayByRouter) {
      return true
    }
    // const unContributeBoardIDs = await getAllWaitingForYouBoardIds(this.userInfo)
    // if (unContributeBoardIDs.length) {
    //   this.message = `Hi ${this.userInfo.displayName},`
    // }
  }
}
