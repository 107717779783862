
import moment from 'moment-timezone'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IUser } from '@/utils/types'
import { MoreOutlined, SendOutlined, ShareAltOutlined } from '@ant-design/icons-vue'
import BoardFormModal from '@/components/board/BoardFormModal.vue'
import BoardShareModal from '@/components/board/BoardShareModal.vue'
import BoardFormScheduleModal from '@/components/board/BoardFormScheduleModal.vue'
import BoardContributedCount from '@/components/board/BoardContributedCount.vue'
import { DELETE_BOARD } from '@/store/actions'
import { boardBackground } from '@/utils/helpers'

@Options({
  components: {
    BoardFormModal,
    BoardShareModal,
    BoardFormScheduleModal,
    BoardContributedCount,
    ShareAltOutlined,
    MoreOutlined,
    SendOutlined,
  },
  directives: { maska },
  emits: [],
})
export default class BoardItem extends Vue {
  @Prop()
  board!: IBoard

  modalShareVisible = false

  boardBackground = boardBackground
  modalVisible = false
  modalBoardScheduleFormVisible = false
  selectedBoardId = ''
  datetimeFormat = 'MMMM Do YYYY, h:mm a'

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get canEdit() {
    return this.userInfo.id === this.board.userId
  }

  get canContribute() {
    return this.board.contributorEmails?.length && this.board.contributorEmails.indexOf(this.userInfo.email) >= 0
  }

  get createdAt() {
    if (this.board.createdAt) {
      return moment.unix(this.board.createdAt).format(this.datetimeFormat)
    }
  }

  get updatedAt() {
    if (this.board.updatedAt) {
      return moment.unix(this.board.updatedAt).format(this.datetimeFormat)
    }
  }

  gotoEditBoardDetail() {
    if (this.canEdit || this.canContribute) {
      this.$router.push({
        name: 'board_edit',
        params: {
          id: this.board.id,
        },
      })
    } else {
      this.$router.push({
        name: 'board_view',
        params: {
          id: this.board.id,
        },
      })
    }
  }

  onEdit() {
    if (!this.board?.id) {
      return
    }

    this.selectedBoardId = this.board.id
    this.modalVisible = true
  }

  onSchedule() {
    if (!this.board?.id) {
      return
    }

    this.selectedBoardId = this.board.id
    this.modalBoardScheduleFormVisible = true
  }

  async onDelete() {
    await this.$store.dispatch(DELETE_BOARD, this.board.id)
  }
}
