<template>
  <div class="flex flex-wrap gap-4 p-6 bg-white mb-6 rounded-lg shadow">
    <div class="rounded-lg overflow-hidden">
      <a-image
        :preview="false"
        :width="160"
        :height="160"
        src="/img/blank.png"
        :style="[boardBackground(board.boardBg)]"
        :class="[board.boardBg.includes('http') ? 'bg-center bg-cover bg-no-repeat' : '']"
      />
    </div>
    <div class="flex-grow">
      <div class="flex flex-wrap gap-4">
        <div class="flex-grow text-xl">
          <a class="text-black font-semibold" @click="gotoEditBoardDetail">{{ board.title }}</a>
        </div>
        <div class="flex gap-2">
          <!-- Schedule button -->
          <a-tooltip v-if="canEdit">
            <template #title>
              <span>Schedule this board</span>
            </template>
            <a-button type="default" shape="circle" @click="onSchedule">
              <template #icon><SendOutlined /></template>
            </a-button>
          </a-tooltip>

          <!-- Share button -->
          <a-tooltip>
            <template #title>
              <span>Share this board</span>
            </template>
            <a-button type="default" shape="circle" @click="modalShareVisible = true">
              <template #icon><ShareAltOutlined /></template>
            </a-button>
          </a-tooltip>

          <a-button type="default" @click="gotoEditBoardDetail"> View board </a-button>

          <a-dropdown placement="bottomRight" v-if="canEdit">
            <a class="ant-dropdown-link text-black" @click.prevent>
              <MoreOutlined class="text-2xl mt-1" />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item key="0" @click="onEdit"> Edit </a-menu-item>
                <a-menu-item key="1">
                  <a-popconfirm
                    title="Are you sure delete this board?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="onDelete"
                  >
                    <a href="#">Delete</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>

      <div class="flex flex-col h-4/6 text-md mt-4">
        <div class="sm:flex-grow flex-none mb-3">
          <span class="text-gray-400">Recipients: </span>
          <span>
            <a-tag class="mr-2 mb-2" v-for="(recipient, index) in board.recipients" :key="index">{{
              recipient.name
            }}</a-tag>
          </span>
        </div>

        <a-row :gutter="20">
          <a-col :xs="24" :sm="6" class="pt-1">
            <div class="border-dotted border border-gray-200 rounded p-2">
              <div class="text-gray-400 uppercase">Creator</div>
              <span>
                {{ board.userName }}
              </span>
            </div>
          </a-col>
          <a-col :xs="24" :sm="6" class="pt-1">
            <div class="border-dotted border border-gray-200 rounded p-2">
              <div class="text-gray-400 uppercase">Created</div>
              <span>
                {{ createdAt }}
              </span>
            </div>
          </a-col>
          <a-col :xs="24" :sm="6" class="pt-1">
            <div class="border-dotted border border-gray-200 rounded p-2">
              <div class="text-gray-400 uppercase">Updated</div>
              <span>
                {{ updatedAt }}
              </span>
            </div>
          </a-col>
          <a-col :xs="24" :sm="6" class="pt-1 sm:text-left md:text-right">
            <div class="border-dotted border border-gray-200 rounded p-2">
              <div class="text-gray-400 uppercase">Contributed</div>
              <span>
                <BoardContributedCount :board="board" />
              </span>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>

  <BoardFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :id="selectedBoardId"
    @update:closeModal="modalVisible = false"
  />

  <BoardFormScheduleModal
    v-if="modalBoardScheduleFormVisible"
    :modalVisible="modalBoardScheduleFormVisible"
    :id="selectedBoardId"
    @update:closeModal="modalBoardScheduleFormVisible = false"
  />

  <BoardShareModal
    v-if="modalShareVisible"
    :modalVisible="modalShareVisible"
    :board="board"
    @update:closeModal="modalShareVisible = false"
  />
</template>

<script lang="ts">
import moment from 'moment-timezone'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IUser } from '@/utils/types'
import { MoreOutlined, SendOutlined, ShareAltOutlined } from '@ant-design/icons-vue'
import BoardFormModal from '@/components/board/BoardFormModal.vue'
import BoardShareModal from '@/components/board/BoardShareModal.vue'
import BoardFormScheduleModal from '@/components/board/BoardFormScheduleModal.vue'
import BoardContributedCount from '@/components/board/BoardContributedCount.vue'
import { DELETE_BOARD } from '@/store/actions'
import { boardBackground } from '@/utils/helpers'

@Options({
  components: {
    BoardFormModal,
    BoardShareModal,
    BoardFormScheduleModal,
    BoardContributedCount,
    ShareAltOutlined,
    MoreOutlined,
    SendOutlined,
  },
  directives: { maska },
  emits: [],
})
export default class BoardItem extends Vue {
  @Prop()
  board!: IBoard

  modalShareVisible = false

  boardBackground = boardBackground
  modalVisible = false
  modalBoardScheduleFormVisible = false
  selectedBoardId = ''
  datetimeFormat = 'MMMM Do YYYY, h:mm a'

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get canEdit() {
    return this.userInfo.id === this.board.userId
  }

  get canContribute() {
    return this.board.contributorEmails?.length && this.board.contributorEmails.indexOf(this.userInfo.email) >= 0
  }

  get createdAt() {
    if (this.board.createdAt) {
      return moment.unix(this.board.createdAt).format(this.datetimeFormat)
    }
  }

  get updatedAt() {
    if (this.board.updatedAt) {
      return moment.unix(this.board.updatedAt).format(this.datetimeFormat)
    }
  }

  gotoEditBoardDetail() {
    if (this.canEdit || this.canContribute) {
      this.$router.push({
        name: 'board_edit',
        params: {
          id: this.board.id,
        },
      })
    } else {
      this.$router.push({
        name: 'board_view',
        params: {
          id: this.board.id,
        },
      })
    }
  }

  onEdit() {
    if (!this.board?.id) {
      return
    }

    this.selectedBoardId = this.board.id
    this.modalVisible = true
  }

  onSchedule() {
    if (!this.board?.id) {
      return
    }

    this.selectedBoardId = this.board.id
    this.modalBoardScheduleFormVisible = true
  }

  async onDelete() {
    await this.$store.dispatch(DELETE_BOARD, this.board.id)
  }
}
</script>
