<template>
  <a-tag color="green">
    <MessageOutlined />
    <strong class="ml-1">{{ contrubutedCount }}</strong>
  </a-tag>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { MessageOutlined } from '@ant-design/icons-vue'
import { IBoard } from '@/utils/types'

@Options({
  components: { MessageOutlined },
  directives: { maska },
  emits: [],
})
export default class BoardContributedCount extends Vue {
  @Prop()
  board!: IBoard

  get contrubutedCount() {
    return this.board.contributedCount || 0
  }
}
</script>
